@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.footer {
  flex-shrink: 0;

  &.home {
    --footer-link-clr: var(--clr-n1);
    --footer-link-clr-hover: rgba(255, 255, 255, 0.8);
    --footer-copy-clr: rgba(255, 255, 255, 0.8);
    --footer-bar-border-clr: rgba(255, 255, 255, 0.3);
    --dev-logo-color: rgba(255, 255, 255, 0.8);
    --dev-logo-text-color: var(--clr-n1);
    background: var(--clr-decor-light);

    .devLogo {
      &:hover {
        color: var(--clr-n1);

        :global(.logo-dot) {
          opacity: 0.5;
          fill: var(--clr-n1);
        }
      }
    }
  }

  &.home,
  &.default {
    @include maxWidth($bp-tablet - 1) {
      .bar {
        grid-template-columns: minmax(0, 1fr);
        padding-top: 30px;
        padding-bottom: 28px;
      }

      .nav {
        order: -1;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 0;
        margin-left: calc(-1 * var(--footer-link-h-padding));
      }

      .link {
        font-size: var(--footer-link-fz, 18px);
        font-weight: var(--footer-link-fw, 400);
        padding: var(--footer-link-v-padding, 3px) var(--footer-link-h-padding);
      }

      .dev {
        margin-top: 14px;
        padding-top: 20px;
        font-size: 14px;
        border-top: 1px solid var(--footer-bar-border-clr, var(--clr-n3));
      }

      .devLogo {
        grid-gap: 10px;
        margin-right: 0;
        margin-left: calc(-1 * var(--dev-logo-h-padding));
      }
    }
  }

  &.half {
    .bar {
      grid-template-columns: minmax(0, 1fr);
      padding-top: 30px;
      padding-bottom: 28px;
    }

    .nav {
      order: -1;
      flex-direction: column;
      align-items: flex-start;
      margin-right: 0;
      margin-left: calc(-1 * var(--footer-link-h-padding));
    }

    .link {
      font-size: var(--footer-link-fz, 18px);
      font-weight: var(--footer-link-fw, 400);
      padding: var(--footer-link-v-padding, 3px) var(--footer-link-h-padding);
    }

    .dev {
      margin-top: 14px;
      padding-top: 20px;
      font-size: 14px;
      border-top: 1px solid var(--footer-bar-border-clr, var(--clr-n3));
    }

    .devLogo {
      grid-gap: 10px;
      margin-right: 0;
      margin-left: calc(-1 * var(--dev-logo-h-padding));
    }
  }

  &.home,
  &.default,
  &.half {
    @include maxWidth($bp-lMobile) {
      --footer-copy-fz: 14px;

      .bar {
        grid-gap: 24px;
        padding-bottom: 24px;
      }

      .link {
        padding: var(--footer-link-v-padding, 2px) var(--footer-link-h-padding);
      }

      .dev {
        margin-top: 8px;
      }
    }
  }

  &.default {
    &.no_mobile {
      @include maxWidth($bp-lMobile) {
        display: none;
      }
    }
  }
}

.bar {
  display: grid;
  grid-template-columns: max-content minmax(0, 1fr) max-content;
  grid-gap: 16px;
  align-items: center;
  padding-top: 22px;
  padding-bottom: 24px;
  border-top: 1px solid var(--footer-bar-border-clr, var(--clr-n3));
}

.copyright {
  font-size: var(--footer-copy-fz, 16px);
  color: var(--footer-copy-clr, var(--clr-text-secondary));
}

.nav {
  --footer-link-h-padding: 14px;
  display: flex;
  align-items: center;
  margin-right: calc(-1 * var(--footer-link-h-padding));
}

.link {
  display: block;
  padding: var(--footer-link-v-padding, 14px) var(--footer-link-h-padding);
  font-size: var(--footer-link-fz, 16px);
  font-weight: var(--footer-link-fw, 500);
  color: var(--footer-link-clr, var(--clr-text-secondary));
  text-transform: capitalize;

  &.active {
    cursor: default;
  }

  &:hover:not(.active) {
    color: var(--footer-link-clr-hover, var(--clr-text-main));
    transition: color 0.2s ease;
  }
}

.dev {
  font-size: 16px;
}

.devLogo {
  --dev-logo-h-padding: 10px;
  display: grid;
  grid-gap: 14px;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
  flex-shrink: 0;
  padding: 10px var(--dev-logo-h-padding) 10px;
  margin-right: calc(-1 * var(--dev-logo-h-padding));
  color: var(--dev-logo-color, var(--clr-n7));
  transition: color 0.16s;

  & > span {
    color: var(--dev-logo-text-color, var(--clr-text-secondary));
    opacity: 0.8;
    transition: opacity 0.16s;
  }

  &:hover {
    color: var(--clr-dev1);

    & > span {
      opacity: 1;
    }

    :global(.logo-dot) {
      opacity: 1;
      fill: var(--clr-dev2);
    }
  }
}
