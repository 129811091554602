.field {
  position: relative;
}

.label {
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3;
  color: var(--clr-text-accent);

  .optional {
    margin-left: 6px;
    font-size: 14px;
    font-weight: normal;
    color: var(--clr-text-secondary);
  }

  & + .description {
    margin-top: -7px;
  }
}

.description {
  margin-bottom: 12px;
  font-size: 13px;
  line-height: 1.3;
  color: var(--clr-text-secondary);
}

.error {
  position: absolute;
  top: 100%;
  left: 0;
  padding-top: 4px;
  font-size: 13px;
  line-height: 1.3;
  color: var(--clr-decor-main);
  transform-origin: top left;

  &.on-enter {
    opacity: 0;
    transform: translateY(-2px);
  }

  &.on-enter-active {
    opacity: 1;
    transform: none;
    transition: opacity 160ms, transform 160ms;
  }

  &.on-exit {
    opacity: 1;
    transform: none;
  }

  &.on-exit-active {
    opacity: 0;
    transform: translateY(-2px);
    transition: opacity 160ms, transform 160ms;
  }
}
