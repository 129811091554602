@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.rating {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 8px;
  align-items: center;
}

.tipTrigger {
  display: flex;
  flex-shrink: 0;
  width: 16px;
  cursor: pointer;

  svg {
    width: 100%;
    height: auto;
  }
}

.tip {
  position: relative;
  padding-right: 16px;

  & ~ [data-popper-arrow] {
    position: absolute;
    z-index: -1;
    width: 28px;
    height: 28px;
    background: inherit;
    visibility: hidden;

    &::before {
      visibility: visible;
      content: '';
      position: absolute;
      z-index: -1;
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      background: inherit;
      transform: rotate(45deg);
    }
  }
}

.tipClose {
  position: absolute;
  top: -16px;
  right: -16px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  color: var(--clr-text-secondary);
  transition: color 0.16s;

  svg {
    width: 12px;
    height: auto;
  }

  @include noTouchHover() {
    color: var(--clr-text-accent);
  }
}

.stars {
  display: grid;
  grid-template-columns: repeat(5, 18px);
  grid-gap: 2px;
  align-items: center;
}

.star {
  display: flex;
  flex-shrink: 0;
  color: var(--clr-n3);

  &.checked {
    color: var(--clr-decor-main);
  }

  svg {
    width: 100%;
    height: auto;
  }
}
