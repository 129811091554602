@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 32px;
  padding-bottom: 60px;

  @include maxWidth($bp-lMobile) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.success {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  align-items: center;
  max-width: 335px;
  padding: 56px 0;

  --paragraph-fz: 18px;

  @include maxWidth($bp-lMobile) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
