@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.description {
  margin-bottom: 56px;
  font-size: 18px;

  strong {
    font-weight: 500;
    color: var(--clr-text-accent);
  }

  @include maxWidth($bp-lMobile) {
    display: none;
  }
}

.collapse {
  margin-bottom: 24px;

  @include minWidth($bp-lMobile + 1) {
    display: none;
  }
}

.drawer_btn {
  display: inline;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  background-position: left bottom;
  background-repeat: repeat-x;
  background-image: linear-gradient(
    90deg,
    var(--clr-text-main),
    var(--clr-text-main) 40%,
    rgba(113, 120, 153, 0) 0%,
    rgba(113, 120, 153, 0) 100%
  );
  background-size: 5px 1px;
  border: none;

  &:hover:not(.active) {
    --link-bcolor: var(--clr-text-secondary);
  }
}
