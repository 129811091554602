@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-top: 32px;
  padding-bottom: 48px;

  --title-mb: 40px;

  @include maxWidth($bp-lMobile) {
    padding-top: 20px;
    padding-bottom: 110px;
    --title-mb: 20px;
  }
}

.masters {
  margin-bottom: 56px;

  & > * {
    margin-bottom: -1px;
  }

  @include maxWidth($bp-lMobile) {
    margin-bottom: 0;
  }
}

.footer {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(2, max-content);

  @include minWidth($bp-lMobile + 1) {
    &.sticky {
      position: sticky;
      bottom: 30px;
      padding: 16px;
      margin: 0 auto;
      background: var(--clr-n1);
      box-shadow: 0px 0px 12px rgba(22, 33, 81, 0.04);
      border-radius: 16px;
    }
  }

  @include maxWidth($bp-lMobile) {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 3;
    grid-template-columns: max-content minmax(0, 1fr);
    width: 100%;
    padding: 16px 20px;
    background: var(--clr-n1);
    border-top: none;
    box-shadow: 0px 0px 12px rgba(22, 33, 81, 0.08);

    --button-pt: 12px;
    --button-pb: 16px;
    --button-pl: 26px;
    --button-pr: 26px;
    --button-bradius: 10px;
    --button-fz: 18px;
  }

  @include maxWidth($bp-sMobile - 1) {
    --button-pl: 18px;
    --button-pr: 18px;
    --button-fz: 16px;
  }
}

.master_name {
  margin-bottom: 4px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  color: var(--clr-text-accent);
}

.master_phone {
  margin-bottom: 40px;
  font-size: 16px;
  line-height: 1.3;
  color: var(--clr-text-main);

  @include maxWidth($bp-lMobile) {
    margin-bottom: 32px;
  }
}

.success {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .link {
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}
