@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

$projects-bg: #e6ebf5;

.description {
  --section-pb-desktop: 132px;
  --section-pb-tablet: 100px;
  --section-pb-mobile: 40px;

  .container {
    display: grid;
    grid-gap: 100px;
    grid-template-columns: minmax(0, 1fr) 383px;
    align-items: flex-start;
    max-width: 984px;

    @include maxWidth($bp-tablet) {
      grid-template-columns: minmax(0, 6.5fr) minmax(0, 3.5fr);
      grid-gap: 120px 40px;
    }

    @include maxWidth($bp-mTablet - 1) {
      grid-gap: 60px 40px;
    }

    @include maxWidth($bp-lMobile) {
      grid-template-columns: minmax(0, 1fr);
      grid-gap: 40px;
    }
  }

  .img {
    position: relative;
    width: 100%;
    border-radius: 40px;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      content: '';
      display: block;
      padding-top: 140%;
    }

    @include maxWidth($bp-lMobile) {
      order: -1;
      max-width: 335px;
      margin: 0 auto;
      border-radius: 32px;
    }
  }

  .steps {
    grid-column: 1 / -1;
    width: 100%;
  }
}

.search {
  --b-padding: 100px;
  padding-bottom: var(--b-padding);
  background: linear-gradient(
    #fff,
    #fff calc(50% - var(--b-padding) / 2),
    #{$projects-bg} calc(50% - var(--b-padding) / 2),
    #{$projects-bg} 100%
  );

  @include maxWidth($bp-tablet) {
    --b-padding: 80px;
  }

  .dialog {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(380px, 2fr);
    align-items: center;
    grid-gap: 96px;
    padding: 56px 100px 70px;
    border-radius: 40px;
    background: var(--clr-n11);
    box-shadow: 0px 20px 60px -20px rgba(35, 37, 170, 0.7);

    --title-color: var(--clr-n1);
    --paragraph-color: rgba(255, 255, 255, 0.8);
    --paragraph-mb: 36px;
    --text-mark-color: var(--clr-decor-light);

    @include maxWidth($bp-tablet) {
      grid-gap: 48px 40px;
      align-items: flex-start;
      padding: 78px 48px 82px;
    }

    @include maxWidth($bp-mTablet - 1) {
      grid-template-columns: minmax(0, 1fr);
      grid-gap: 40px;
      align-items: flex-start;
      padding: 48px 40px;
      border-radius: 32px;

      --paragraph-mb: 24px;
    }

    @include maxWidth($bp-mobile) {
      padding: 48px 24px 24px;
    }
  }

  .img {
    position: relative;
    width: 100%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      content: '';
      display: block;
      padding-top: 95.36%;
    }

    @include maxWidth($bp-mTablet - 1) {
      max-width: 195px;
      margin: 0 auto;
    }
  }
}

.projects {
  background-color: #{$projects-bg};

  --title-mb: 0;
  --section-pt-desktop: 46px;
  --section-pt-tablet: 20px;
  --section-pt-mobile: 0;
  --section-pb-desktop: 145px;
  --section-pb-tablet: 70px;
  --section-pb-mobile: 20px;
  --full-width-slider-pt: 30px;
  --full-width-slider-pb: 30px;

  .header {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: minmax(0, 1fr) max-content;
    align-items: flex-start;
    margin-bottom: 23px;
  }

  .link {
    position: relative;
    display: block;
    padding: 13px 39px 14px 23px;
    margin-top: 6px;
    font-size: 16px;
    line-height: 1;
    color: var(--clr-text-main);
    border: 1px solid var(--clr-n7);
    border-radius: 10px;
    transition: border-color 0.16s;

    &::after {
      content: '';
      position: absolute;
      top: 13px;
      right: 19px;
      display: block;
      width: 16px;
      height: 16px;
      background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.693 1.11a1 1 0 011.415 0l3.535 3.536A1 1 0 014.23 6.06L.693 2.524a1 1 0 010-1.414z' fill='%23989EB4'/%3E%3Cpath d='M.693 9.64a1 1 0 010-1.415L4.23 4.69a1 1 0 111.414 1.414L2.108 9.639a1 1 0 01-1.415 0z' fill='%23989EB4'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      transform: translate3d(0, 0, 0);
      transition: transform 0.16s;
    }

    &:hover {
      border-color: var(--clr-text-secondary);

      &::after {
        transform: translate3d(2px, 0, 0);
      }
    }
  }
}

.advantages {
  --title-mb: 100px;

  @include maxWidth($bp-tablet) {
    --title-mb: 85px;
  }

  @include maxWidth($bp-lMobile) {
    --title-mb: 36px;
  }
}

.advantage_list {
  display: grid;
  grid-gap: 60px;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  &:not(:last-child) {
    padding-bottom: 70px;
    margin-bottom: 70px;
    border-bottom: 1px solid var(--clr-n3);
  }

  .icon_shift {
    --adv-icon-space-left: 4px;
  }

  @include maxWidth($bp-tablet) {
    grid-gap: 40px;

    &:not(:last-child) {
      padding-bottom: 58px;
    }
  }

  @include maxWidth(830) {
    grid-template-columns: minmax(0, 1fr);

    &:not(:last-child) {
      padding-bottom: 0;
      margin-bottom: 40px;
      border-bottom: none;
    }
  }

  @include maxWidth($bp-lMobile) {
    grid-gap: 26px;

    &:not(:last-child) {
      margin-bottom: 26px;
    }
  }
}
