@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.page {
  padding-top: 32px;
  padding-bottom: 80px;

  --title-mb: 40px;
  --paragraph-mb: 56px;
  --project-h-space: 24px;
  --paragraph-fz: 20px;

  @include maxWidth($bp-mTablet) {
    --project-h-space: 20px;
  }

  @include maxWidth($bp-lMobile) {
    padding-top: 36px;
    padding-bottom: 46px;

    --title-mb: 26px;
    --paragraph-mb: 36px;
  }
}

.projects {
  display: grid;
  grid-gap: 38px;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  @include maxWidth(1200) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 28px;
  }

  @include maxWidth($bp-mTablet) {
    grid-gap: 20px;
  }

  @include maxWidth($bp-lMobile) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 20px;
  }

  @include maxWidth(400) {
    grid-template-columns: minmax(0, 1fr);
  }
}
