@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.text {
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;

  mark {
    background: 0 0;
    color: var(--text-mark-color, var(--clr-decor-main));
  }
}
