@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.page {
  padding: 30px 0 40px;

  --title-mb: 40px;

  @include maxWidth($bp-lMobile) {
    --title-mb: 30px;
  }
}

.limiter {
  max-width: 860px;
}
