@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
  background-color: rgba(6, 10, 30, 0.1);

  &.on-enter {
    opacity: 0;
  }

  &.on-enter-active {
    opacity: 1;
    transition: opacity 0.1s linear;
  }

  &.on-enter-done {
    opacity: 1;

    .container {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  &.on-exit {
    opacity: 1;
  }

  &.on-exit-active {
    opacity: 0;
    transition: opacity 0.1s linear;
  }
}

.container {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  min-width: 250px;
  width: 100%;
  max-width: 496px;
  background: var(--clr-n1);
  opacity: 0;
  overflow: hidden;
  cursor: default;
  transform: translate3d(20%, 0, 0);
  box-shadow: 0px 0px 12px rgba(22, 33, 81, 0.03);
  transition: transform 0.2s cubic-bezier(0.7, 0.3, 0.1, 1),
    opacity 0.2s cubic-bezier(0.7, 0.3, 0.1, 1);

  &.no_title {
    padding-top: 54px;
  }

  @include maxWidth($bp-mobile) {
    max-width: none;
  }
}

.dialog {
  width: 100%;
  height: 100%;
  padding: 40px;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;

  @include scrollBar();

  @include maxWidth($bp-lMobile) {
    padding: 24px 20px 40px;
  }
}

.close {
  position: absolute;
  top: 34px;
  right: 32px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  color: var(--clr-text-secondary);
  transition: color 0.2s;

  svg {
    width: 24px;
    height: auto;
  }

  &:hover {
    color: var(--clr-text-main);
  }

  @include maxWidth($bp-lMobile) {
    top: 18px;
    right: 12px;
  }
}

.title {
  margin-bottom: 40px;
  padding-right: 40px;

  @include maxWidth($bp-lMobile) {
    margin-bottom: 32px;
    padding-right: 32px;
  }
}
