@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.title {
  display: block;
  max-width: var(--title-max-width, 100%);
  font-family: var(--font-secondary);
  font-weight: 800;
  font-size: var(--title-fz, 44px);
  line-height: 1.3;
  text-align: var(--title-align, left);
  color: var(--title-color, var(--clr-text-accent));

  &:not(:last-child) {
    margin-bottom: var(--title-mb, 32px);
  }

  @include maxWidth($bp-lMobile) {
    font-size: var(--title-fz, 32px);
  }

  &.center {
    text-align: var(--title-align, center);
  }

  &.medium {
    font-size: var(--title-fz, 32px);

    &:not(:last-child) {
      margin-bottom: var(--title-mb, 24px);
    }

    @include maxWidth($bp-lMobile) {
      font-size: var(--title-fz, 28px);
    }
  }
}
