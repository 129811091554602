@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.wrapper {
  position: fixed;
  top: 7px;
  right: 38px;
  z-index: 101;
  display: flex;
  overflow: visible;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  color: var(--burger-color, var(--clr-n1));
  border: 0;
  cursor: pointer;
  background-color: transparent;
  transition-duration: 0.15s;
  transition-property: opacity, filter;

  &.dark {
    --burger-color: var(--clr-accent-main);
  }

  &.open {
    --burger-color: var(--clr-accent-main);

    .inner {
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: rotate(45deg);

      &::before {
        top: 0;
        transition: top 75ms ease, opacity 75ms ease 0.12s;
        opacity: 0;
      }

      &::after {
        bottom: 0;
        transition: bottom 75ms ease, transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
        transform: rotate(-90deg);
      }
    }
  }

  @include maxWidth($bp-lMobile) {
    right: 10px;
  }
}

.inner {
  position: relative;
  display: block;
  width: 25px;
  height: 2px;
  background-color: currentColor;
  border-radius: 4px;
  transition-property: transform;
  transition-duration: 75ms;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

  &::before,
  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
    background-color: currentColor;
  }

  &::before {
    top: -8px;
    transition: top 75ms ease 0.12s, opacity 75ms ease;
  }

  &::after {
    bottom: -8px;
    transition: bottom 75ms ease 0.12s, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
}
