@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.project {
  position: relative;
  z-index: 2;
  border-radius: 20px;
  box-shadow: 0px 12px 25px rgba(35, 37, 170, 0.04);
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  transition: transform 0.2s;

  @include noTouchHover() {
    transform: translate3d(0, -4px, 0);
  }
}

.action {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.header {
  position: relative;
  z-index: 1;
}

.img {
  position: relative;
  z-index: 0;
  width: 100%;

  &::after {
    content: '';
    display: block;
    padding-top: 120%;
  }

  img {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.count {
  position: absolute;
  z-index: 1;
  left: var(--project-h-space, 30px);
  bottom: var(--project-h-space, 30px);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  padding: 7px 6px 8px;
  font-size: 12px;
  line-height: 1;
  font-weight: bold;
  font-family: var(--font-secondary);
  color: var(--clr-n1);
  border-radius: 6px;
  background: rgba(80, 87, 120, 0.8);
}

.footer {
  padding: 22px var(--project-h-space, 30px) 24px;
  line-height: 1.3;
  background: var(--clr-n1);
}

.name {
  margin-bottom: 4px;
  font-size: 20px;
  font-weight: 500;
  color: var(--clr-text-accent);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.descr {
  font-size: 18px;
  color: var(--clr-text-secondary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
