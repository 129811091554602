@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.map {
  position: sticky;
  top: 0;
  width: 100%;
  height: 100vh;
}

.error {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  padding: 20px;

  @include maxWidth($bp-halved-layout-transform) {
    display: none;
  }

  &.on-enter {
    opacity: 0;
    transform: translateY(-20px);
  }

  &.on-enter-active {
    opacity: 1;
    transform: none;
    transition: opacity 160ms, transform 160ms;
  }

  &.on-exit {
    opacity: 1;
    transform: none;
  }

  &.on-exit-active {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 160ms, transform 160ms;
  }
}

.error_inner {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 18px 20px;
  color: var(--clr-n13);
  font-weight: 500;
  box-shadow: 0px 0px 12px rgba(22, 33, 81, 0.04);
  border-radius: 16px;
  background-color: #fff;
}

.error_icon {
  display: flex;
  margin-right: 10px;
  color: var(--clr-decor-main);
}
