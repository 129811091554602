@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.section {
  padding-top: var(--section-pt-desktop, 148px);
  padding-bottom: var(--section-pb-desktop, 148px);

  @include maxWidth($bp-tablet) {
    padding-top: var(--section-pt-tablet, 102px);
    padding-bottom: var(--section-pb-tablet, 104px);
  }

  @include maxWidth($bp-lMobile) {
    padding-top: var(--section-pt-mobile, 48px);
    padding-bottom: var(--section-pb-mobile, 48px);
  }
}
