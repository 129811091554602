@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.zip_code_wrapper {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 40px;
  align-items: center;

  @include maxWidth($bp-lMobile) {
    grid-gap: 32px;
  }
}

.zip_code,
.miles_count {
  --input-max-width: 140px;
}

.zip_code_icon {
  display: grid;
  grid-template-columns: 72px 100px;
  align-items: center;
  grid-gap: 8px;
  margin-top: -14px;
  margin-bottom: -10px;
  color: var(--clr-text-accent);

  svg {
    vertical-align: middle;
    width: 100%;
    height: auto;
  }
}

.zip_code_text {
  font-family: var(--font-secondary);
  font-weight: bold;
  font-size: 22px;
  line-height: 1.3;
}

.miles_count_wrapper {
  position: relative;
}

.miles_count_text {
  position: absolute;
  left: 154px;
  bottom: 16px;
  color: var(--clr-text-secondary);
}
