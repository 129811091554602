@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.upload {
  &.disabled .button {
    cursor: default;
    color: var(--clr-n7);
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23BBBFD1' stroke-width='1' stroke-dasharray='1%2c 5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

    mark {
      color: inherit;
    }
  }

  &.error .button {
    color: var(--clr-decor-semilight);
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23fc5151' stroke-width='1' stroke-dasharray='1%2c 5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

    mark {
      color: inherit;
    }
  }
}

.button {
  position: relative;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 8px;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 92px;
  padding: 32px 20px 34px;
  font-size: 16px;
  line-height: 1.3;
  border-radius: 6px;
  cursor: pointer;
  color: var(--clr-text-secondary);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23989eb4' stroke-width='1' stroke-dasharray='1%2c 5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  & > * {
    flex-shrink: 0;
  }

  svg {
    width: 23px;
    height: auto;
  }

  mark {
    background: 0 0;
    color: var(--clr-text-accent);
  }

  &:hover,
  &.dragging {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23162151' stroke-width='1' stroke-dasharray='1%2c 5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }

  @include maxWidth($bp-mobile) {
    font-size: 13px;
  }

  @include maxWidth($bp-sMobile - 1) {
    grid-gap: 6px;
    font-size: 12px;

    svg {
      width: 18px;
    }
  }
}

.input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  pointer-events: none;
  white-space: nowrap;
}

.preview {
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 10px;
  font-size: 16px;
  line-height: 1.3;
  color: var(--clr-n15);

  svg {
    width: 15px;
    height: auto;
    margin-right: 8px;
    color: var(--clr-n14);
  }
}

.name {
  max-width: calc(100% - 15px - 28px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
}

.remove {
  display: block;
  width: 28px;
  height: 30px;
  background-image: url("data:image/svg+xml,%3Csvg width='11' height='11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.387.785a.833.833 0 011.178 0l8.25 8.25a.833.833 0 11-1.179 1.178l-8.25-8.25a.833.833 0 010-1.178z' fill='%23989EB4'/%3E%3Cpath d='M.387 10.213a.833.833 0 010-1.178l8.25-8.25a.833.833 0 111.178 1.179l-8.25 8.25a.833.833 0 01-1.178 0z' fill='%23989EB4'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
}
