@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.wrapper {
  position: relative;
  z-index: 8;
  display: grid;
  grid-template-columns: 154px max-content;
  grid-gap: 24px 20px;

  @include maxWidth($bp-mobile) {
    grid-template-columns: minmax(0, 1fr);
  }
}

.field {
  position: relative;
  border-bottom: 1px solid var(--search-bcolor, rgba(255, 255, 255, 0.3));
  transition: border-color 0.2s;

  &:hover {
    border-color: var(--search-bcolor-hover, rgba(255, 255, 255, 0.4));
  }
}

.input {
  width: 100%;
  padding: 12px 30px 16px 0;
  font-size: 22px;
  line-height: 1;
  color: var(--clr-n1);
  background: 0 0;

  &::placeholder {
    color: var(--search-ph-color, rgba(255, 255, 255, 0.8));
  }
}

.message {
  position: absolute;
  left: 0;
  bottom: calc(100% + 24px);
  z-index: 7;
  display: grid;
  grid-template-columns: 72px minmax(0, 1fr);
  grid-gap: 18px;
  width: 100%;
  padding: 20px 56px 30px 20px;
  font-size: 16px;
  line-height: 1.5;
  color: var(--clr-text-accent);
  background: var(--clr-n3);
  border-radius: 10px;

  & > svg {
    width: 100%;
    height: auto;
  }

  &::before {
    content: '';
    position: absolute;
    left: 20px;
    bottom: -4px;
    z-index: -1;
    width: 28px;
    height: 28px;
    border-radius: 4px;
    background: inherit;
    transform: rotate(45deg);
  }

  &.on-enter {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  &.on-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 200ms, transform 200ms;
  }

  &.on-exit {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  &.on-exit-active {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
    transition: opacity 200ms, transform 200ms;
  }

  @include maxWidth($bp-lMobile) {
    padding-right: 36px;
  }

  @include maxWidth($bp-sMobile - 1) {
    grid-template-columns: minmax(0, 1fr);

    & > svg {
      display: none;
    }
  }
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: inherit;
  color: var(--clr-text-secondary);
}

.tips {
  margin-top: 16px;
  color: var(--clr-text-main);

  & button {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    color: var(--clr-text-accent);
    border-bottom: 1px solid var(--clr-n7);
  }
}

.result {
  position: absolute;
  right: 0;
  bottom: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  color: var(--clr-text-accent);
  border-radius: 50%;
  opacity: 0;
  transform: scale(0.6);
  transition: opacity 0.16s, transform 0.16s;

  svg {
    width: 8px;
    height: auto;
  }

  &.success {
    background: var(--clr-n14);

    svg {
      width: 9px;
    }
  }

  &.error {
    background: var(--clr-decor-main);
  }

  &.visible {
    opacity: 1;
    transform: scale(1);
  }
}
