@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.wrap {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: 320px;

  &.gradient {
    background: linear-gradient(180deg, #f7f7ff 0%, rgba(255, 255, 255, 0) 100%);
    background-size: 100% 170px;
    background-repeat: no-repeat;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.menu_toggler {
  @include minWidth($bp-lMobile + 1) {
    display: none;
  }
}

.halved {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  height: 100vh;

  @include maxWidth($bp-halved-layout-transform) {
    grid-template-columns: minmax(0, 1fr);

    & > *:last-child {
      display: none;
    }
  }
}

.halved_inner {
  position: relative;
  z-index: 50;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #f7f7ff 0%, rgba(255, 255, 255, 0) 100%);
  background-size: 100% 170px;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 12px rgba(22, 33, 81, 0.1);

  .content {
    flex: 1 0 auto;
  }
}

.halved_side {
  position: relative;
  z-index: 49;
  background: #f7f7ff;
}
