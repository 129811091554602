@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.tooltip {
  max-width: 280px;
  padding: 24px;
  font-size: 16px;
  color: var(--clr-text-accent);
  background: var(--clr-n3);
  border-radius: 10px;

  &.custom {
    padding: 0;
  }

  *::selection {
    background-color: rgba(255, 255, 255, 0.6);
  }
}
