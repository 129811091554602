@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.gallery {
  height: 100%;
  padding-bottom: 34px;

  :global(.swiper-container) {
    height: 100%;
  }

  :global(.swiper-slide) {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0 !important;
  }

  :global(.swiper-slide-active) {
    opacity: 1 !important;
  }

  --swiper-btn-width: 96px;

  @include maxWidth($bp-lMobile) {
    padding-bottom: 20px;
    --swiper-btn-width: 64px;
  }
}

.header {
  display: grid;
  grid-template-columns: max-content minmax(0, 1fr);
  grid-gap: 40px;
  padding: 34px 40px;
  color: var(--clr-n1);
  text-align: center;
  font-size: 16px;
  line-height: 1.3;
  color: rgba(255, 255, 255, 0.5);
  user-select: none;

  @include maxWidth($bp-lMobile) {
    padding: 20px;
    font-size: 14px;
  }
}

.pagination {
  min-width: 70px;
  text-align: left;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
  color: var(--clr-n1);
}

.name {
  margin-bottom: 2px;
  font-size: 22px;
  font-weight: 500;
  color: var(--clr-n1);

  @include maxWidth($bp-lMobile) {
    font-size: 14px;
  }
}

.img {
  position: relative;
  width: 100%;
  max-width: calc(100% - var(--swiper-btn-width) * 2);
  height: 100%;
  max-height: 630px;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @include maxWidth($bp-sMobile) {
    max-width: calc(100% - 20px * 2);
    max-height: 460px;
  }
}
