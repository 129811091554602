@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.advantage {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: var(--adv-circle-size, 116px) minmax(0, 1fr);
  align-items: flex-start;
  font-size: 22px;
  line-height: 1.6;

  @include maxWidth($bp-lMobile) {
    grid-gap: 20px;
    --adv-circle-size: 64px;
    --adv-icon-height: 27px;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 50%;
  color: var(--clr-n11);
  background: var(--clr-n2);

  &::after {
    content: '';
    display: block;
    padding-top: 100%;
  }

  svg {
    display: block;
    width: auto;
    height: var(--adv-icon-height, 48px);
    margin-left: var(--adv-icon-space-left, 0px);
  }
}

.title {
  display: block;
  padding-top: 6px;
  margin-bottom: 8px;
  font-weight: 500;
  color: var(--clr-text-accent);

  @include maxWidth($bp-lMobile) {
    padding-top: 12px;
  }

  @include maxWidth($bp-sMobile - 1) {
    line-height: 1.3;
  }
}

.description {
  color: var(--clr-text-secondary);

  @include maxWidth($bp-sMobile - 1) {
    font-size: 20px;
  }
}
