@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
  background: var(--clr-n1);
  transition: visibility 0s 0.15s, opacity 0.15s ease;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  user-select: none;

  @include maxWidth($bp-lMobile) {
    &.visible {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
      transition: none;
      transition: visibility 0s, opacity 0.15s ease;

      .menu {
        opacity: 1;
      }
    }
  }
}

.menu {
  position: relative;
  height: 100%;
  min-width: 250px;
  width: 100%;
  padding-top: 64px;
  background: var(--clr-n1);
  opacity: 0;
  overflow: hidden;
  cursor: default;
  transition: opacity 0.2s linear;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-bottom: 60px;
}

.logo {
  position: absolute;
  top: 2px;
  left: 28px;
  display: block;
  padding: 20px;
  font-family: var(--font-secondary);
  font-weight: bold;
  font-size: 20px;
  line-height: 1;
  color: var(--clr-accent-main);

  &.active {
    cursor: default;
  }

  @include maxWidth($bp-lMobile) {
    left: 0;
  }
}

.nav_link {
  display: block;
  width: 100%;
  padding: 12px;
  font-size: 22px;
  text-align: center;
  text-transform: capitalize;
  color: var(--clr-text-accent);

  &.active {
    font-weight: bold;
  }
}

.nav_icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  color: var(--nav-icon-color, var(--clr-text-secondary));
}
