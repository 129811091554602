@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.input {
  display: block;
  width: 100%;
  max-width: var(--input-max-width, none);
  padding: var(--input-pt, 13px) var(--input-pr, 13px) var(--input-pb, 16px) var(--input-pl, 14px);
  font-size: var(--input-fz, 18px);
  line-height: 1.3;
  color: var(--input-color, var(--clr-text-accent));
  caret-color: currentColor;
  border: 1px solid var(--input-bcolor, var(--clr-n3));
  border-radius: var(--input-bradius, 6px);
  background: var(--input-bg, var(--clr-n3));
  transition: border-color 0.2s ease, color 0.2s ease, background-color 0.2s ease;

  &:hover,
  &:focus {
    --input-bcolor: var(--clr-n7);
  }

  &:focus {
    --input-bg: var(--clr-n1);
  }

  &::placeholder {
    color: var(--clr-text-secondary);
  }
}

.danger {
  --input-bcolor: var(--clr-decor-main);

  &:hover {
    --input-bcolor: var(--clr-decor-semilight);
  }

  &:focus {
    --input-bcolor: var(--clr-decor-dark);
  }
}

.textarea {
  min-height: var(--input-min-height, 202px);

  @include scrollBar(var(--clr-n1), var(--clr-text-secondary), 4px);
}

.number_wrap {
  position: relative;
  padding: 0;
  overflow: hidden;
}

.number_input {
  display: block;
  width: 100%;
  padding: var(--input-pt, 13px) var(--input-pr, 50px) var(--input-pb, 16px) var(--input-pl, 50px);
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  text-align: center;
  background: 0 0;

  &::placeholder {
    color: var(--clr-text-secondary);
  }
}

.number_btn {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 100%;
  color: var(--clr-text-main);
  background: 0 0;
  user-select: none;
  transition: color 0.2s;

  &:hover:not(:disabled) {
    color: var(--clr-text-accent);
  }

  &:disabled {
    cursor: default;
    opacity: 0.6;
  }

  svg {
    width: 14px;
    height: auto;
  }

  &.minus {
    left: 0;
  }

  &.plus {
    right: 0;
  }
}
