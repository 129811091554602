@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.bar {
  position: fixed;
  top: 64px;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 3px;
  overflow: hidden;

  @include minWidth($bp-lMobile + 1) {
    display: none;
    pointer-events: none;
    visibility: hidden;
  }
}

.thumb {
  width: 100%;
  height: 100%;
  margin-left: -100%;
  background: var(--clr-decor-main);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  will-change: transform;
  transition: transform 0.2s ease-in;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.limiter {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 530px;
  padding: 40px 0;
  margin: 0 auto;

  @include maxWidth($bp-lMobile) {
    justify-content: flex-start;
    max-width: none;
    margin: 0;
    padding: 20px 0 120px;
  }
}

.steps {
  padding-top: 16px;
  font-size: 16px;
  font-weight: 500;
  color: var(--clr-text-main);
}

.content {
  padding-bottom: 48px;
  --title-mb: 38px;

  @include maxWidth($bp-lMobile) {
    --title-mb: 18px;
    --title-align: left;
  }
}

.options {
  padding-left: 118px;

  @include maxWidth($bp-lMobile) {
    padding-left: 0;
  }
}

.option_icons {
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(2, 168px);
  justify-content: center;

  @include maxWidth(430) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.footer {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(2, max-content);
  justify-content: center;
  padding-top: 56px;
  border-top: 1px solid var(--clr-n3);

  @include maxWidth($bp-lMobile) {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 3;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    width: 100%;
    padding: 16px 20px;
    background: var(--clr-n1);
    border-top: none;
    box-shadow: 0px 0px 12px rgba(22, 33, 81, 0.08);

    --button-pt: 12px;
    --button-pb: 16px;
    --button-pl: 20px;
    --button-pr: 20px;
    --button-bradius: 10px;
    --button-fz: 18px;
  }
}
