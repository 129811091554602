@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.master {
  display: grid;
  grid-gap: 48px;
  grid-template-columns: minmax(460px, 1.5fr) minmax(0, 1fr) max-content;
  align-items: center;
  padding: 24px;
  border: 1px solid var(--clr-n6);
  border-radius: 14px;

  @include maxWidth($bp-tablet) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) max-content;

    padding: 28px 24px;
  }

  @include maxWidth($bp-mTablet - 1) {
    grid-template-columns: minmax(0, 1fr) max-content;
    align-items: flex-start;
    grid-gap: 20px;
    padding: 20px;
  }
}

.info {
  display: grid;
  grid-gap: 4px 24px;
  align-items: center;
  grid-template-columns: repeat(2, minmax(0, 1fr)) 124px;

  @include maxWidth($bp-tablet - 1) {
    grid-template-columns: minmax(0, 1fr);
  }
}

.name {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  color: var(--clr-text-accent);
  text-transform: capitalize;

  @include maxWidth($bp-lMobile) {
    font-size: 18px;
  }
}

.phone {
  font-size: 18px;
  line-height: 1.3;
  color: var(--clr-text-main);

  @include maxWidth($bp-lMobile) {
    font-size: 16px;
  }
}

.rating {
  @include maxWidth($bp-tablet - 1) {
    padding-top: 3px;
  }
}

.media {
  display: grid;
  grid-template-columns: 80px 102px;
  justify-content: center;
  align-items: center;
  grid-gap: 12px 24px;

  @include maxWidth($bp-mTablet - 1) {
    grid-template-columns: minmax(0, 1fr);
    justify-content: flex-start;
  }
}

.photos {
  display: grid;
  grid-template-columns: repeat(auto-fit, 40px);
  align-items: center;
  grid-gap: 6px;
  font-size: 12px;
  color: var(--clr-text-secondary);

  @include maxWidth($bp-mTablet - 1) {
    grid-column: 1 / -1;
  }

  @include maxWidth($bp-sMobile - 1) {
    grid-template-columns: repeat(auto-fit, 36px);
  }
}

.photo {
  position: relative;
  width: 100%;
  border-radius: 10px;
  box-shadow: inset 0 0 2px var(--clr-n7);
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    overflow: hidden;
  }

  &:last-child {
    &::after {
      content: attr(data-count);
      position: absolute;
      top: 50%;
      left: calc(100% + 12px);
      display: block;
      color: inherit;
      font-size: inherit;
      transform: translateY(-50%);

      @include maxWidth($bp-sMobile - 1) {
        left: calc(100% + 10px);
      }
    }
  }
}

.certificatesBtn {
  display: grid;
  grid-template-columns: 20px minmax(0, 1fr);
  grid-gap: 8px;
  align-items: center;
  grid-column: -2 / -1;
  padding: 8px;
  margin-left: -8px;
  color: var(--clr-text-main);
  font-size: 11px;
  text-align: left;
  transition: opacity 0.16s;

  svg {
    color: var(--clr-text-secondary);
  }

  @include noTouchHover() {
    opacity: 0.8;
  }

  @include maxWidth($bp-mTablet - 1) {
    &:only-child {
      margin-top: -8px;
    }
  }
}

.certificateList {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 8px;
  margin-left: -12px;

  @include maxWidth($bp-lMobile) {
    margin-left: -6px;
  }
}

.certificate {
  display: grid;
  grid-template-columns: 20px minmax(0, 1fr);
  grid-gap: 8px;
  align-items: center;
  padding: 6px;
  color: var(--clr-text-main);
  font-size: 18px;

  svg {
    color: var(--clr-text-secondary);
  }
}

.button {
  grid-column: 3 / -1;

  @include maxWidth($bp-tablet) {
    --button-fz: 0;
    --button-pt: 12px;
    --button-pb: 12px;
    --button-pl: 11px;
    --button-pr: 11px;
  }

  @include maxWidth($bp-mTablet - 1) {
    grid-column: 2 / -1;
    grid-row: 1;
  }
}

.icon {
  display: inline-block;
  vertical-align: middle;

  svg {
    vertical-align: middle;

    @include minWidth($bp-tablet + 1) {
      display: none;
    }
  }
}
