@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.header_home {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100vh;
  min-height: 630px;
  max-height: 1000px;
  color: var(--clr-n1);
  background: linear-gradient(180deg, #223482 0%, #1a2a71 100%);

  --nav-link-clr-hover: rgba(255, 255, 255, 0.8);
  --nav-icon-color: #9099c0;

  @include maxWidth($bp-tablet) {
    height: auto;
    min-height: auto;
    max-height: none;
  }

  @include maxWidth($bp-lMobile) {
    background: #223482;

    .bar {
      background: #223482;
    }
  }
}

.header_default,
.header_half {
  --nav-link-clr: var(--clr-text-accent);
}

.header_default {
  color: var(--clr-accent-main);

  @include maxWidth($bp-lMobile) {
    & + * {
      margin-top: 64px;
    }
  }
}

.header_half {
  position: relative;
  z-index: 10;
  color: var(--clr-accent-main);
  background-color: 0 0;

  @include maxWidth($bp-lMobile) {
    & + * {
      margin-top: 64px;
    }
  }
}

.bar {
  width: 100%;

  flex-shrink: 0;

  @include maxWidth($bp-lMobile) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    height: 64px;
    background: var(--clr-n1);
    box-shadow: 0px 0px 12px rgba(22, 33, 81, 0.1);

    & + * {
      margin-top: 64px;
    }
  }
}

.row {
  display: flex;
  align-items: center;
}

.logo {
  --logo-h-padding: 14px;
  --logo-v-padding: 40px;
  flex-shrink: 0;
  padding: var(--logo-v-padding) var(--logo-h-padding);
  margin-right: auto;
  margin-left: calc(-1 * var(--logo-h-padding));
  font-family: var(--font-secondary);
  font-weight: bold;
  font-size: 26px;
  line-height: 1;
  color: var(--logo-color, inherit);

  &.active {
    cursor: default;
  }

  @include maxWidth($bp-lMobile) {
    --logo-v-padding: 22px;
    font-size: 20px;
  }
}

.nav {
  --nav-link-padding: 16px;
  flex-shrink: 0;
  display: flex;
  margin-right: calc(-1 * var(--nav-link-padding));

  @include maxWidth($bp-lMobile) {
    display: none;
  }
}

.nav_icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  color: var(--nav-icon-color, var(--clr-text-secondary));
}

.nav_link {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 20px var(--nav-link-padding);
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  color: var(--nav-link-clr, inherit);
  text-transform: capitalize;
  transition: color 0.2s ease, background-color 0.2s ease;

  &.active {
    cursor: default;
  }

  &:hover:not(.active) {
    color: var(--nav-link-clr-hover, var(--clr-text-main));
  }
}
