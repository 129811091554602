@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.steps {
  display: grid;
  grid-gap: 64px;
  grid-template-columns: max-content 82px max-content 82px max-content 82px max-content;
  max-width: 984px;
  margin: 0 auto;
  padding-bottom: 86px;

  @include maxWidth(1100) {
    grid-template-columns: max-content 1fr max-content 1fr max-content 1fr max-content;
    grid-gap: 4.5%;
  }

  @include maxWidth($bp-tablet) {
    max-width: 660px;
    padding-bottom: 120px;
  }

  @include maxWidth(700) {
    grid-gap: 3%;
  }

  @include maxWidth($bp-lMobile) {
    grid-template-columns: max-content 1fr max-content;
    grid-template-rows: max-content 35px max-content;
    grid-gap: 10%;
    max-width: 300px;
    padding-bottom: 160px;
  }

  @include maxWidth($bp-sMobile) {
    max-width: calc(100% - 40px);
    grid-gap: 32px 5%;
  }
}

.step {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 148px;
  font-family: var(--font-secondary);
  font-size: 78px;
  font-weight: bold;
  line-height: 1;
  color: #c2c3f0;

  &.end {
    align-items: flex-end;
  }

  &.two {
    color: #9fa0e8;
  }

  &.three {
    color: #7678e0;

    @include maxWidth($bp-lMobile) {
      grid-column: 1 / 2;
    }
  }

  &.four {
    color: #4a4ce2;

    @include maxWidth($bp-lMobile) {
      grid-column: 3 / -1;
    }
  }
}

.line {
  position: relative;
  top: calc(50% + 6px);
  width: 110%;
  height: 4px;
  margin-left: -5%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='4' viewBox='0 0 10 4'%3E%3Cpath stroke='%23BBBFD1' stroke-width='3' stroke-linecap='round' stroke-dasharray='2, 8' d='M2 2h10'/%3E%3C/svg%3E");
  background-repeat: repeat-x;
  background-size: 10px 100%;
  transform: rotate(32deg) translateZ(0);

  &.asc {
    transform: rotate(-32deg) translateZ(0);

    @include maxWidth($bp-lMobile) {
      grid-column: 2 / 3;
    }
  }
}

.number {
  position: relative;
}

.content {
  position: absolute;
  top: calc(100% + 25px);
  left: 50%;
  width: 190px;
  font-family: var(--font-main);
  font-size: 18px;
  font-weight: normal;
  color: var(--clr-text-secondary);
  text-align: center;
  transform: translateX(-50%);

  @include maxWidth($bp-tablet) {
    width: 135px;
  }
}

.title {
  margin-bottom: 12px;
  font-size: 22px;
  font-weight: 500;
  color: var(--clr-text-accent);
  white-space: nowrap;
}

.desc {
  line-height: 1.6;
}
