@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.container {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
}

.promo {
  display: grid;
  grid-template-columns: 620px minmax(0, 1fr);
  grid-gap: 40px;
  align-items: center;
  width: 100%;
  padding: 40px 0;

  --title-color: var(--clr-n1);
  --text-mark-color: var(--clr-decor-light);
  --title-mb: 82px;

  @include maxWidth($bp-tablet) {
    grid-template-columns: minmax(0, 1fr);
    grid-gap: 82px;
    padding-top: 46px;
    padding-bottom: 102px;
  }

  @include maxWidth($bp-lMobile) {
    grid-gap: 48px;
    padding-top: 24px;
    padding-bottom: 40px;

    --title-mb: 48px;
  }

  @include maxWidth(400) {
    grid-gap: 36px;

    --title-max-width: 260px;
    --title-fz: 30px;
    --title-mb: 32px;
  }
}

.img {
  position: relative;
  width: 100%;
  max-width: 526px;
  height: 100%;
  margin-left: auto;
  margin-right: 60px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @include maxWidth($bp-xmDesktop) {
    margin-right: 0;
  }

  @include maxWidth($bp-tablet) {
    order: -1;
    height: auto;
    margin-bottom: 26px;
    margin-right: auto;

    &::after {
      content: '';
      display: block;
      padding-top: 108%;
    }
  }

  @include maxWidth($bp-lMobile) {
    max-width: 240px;
    margin-bottom: 0;
  }

  @include maxWidth(400) {
    max-width: 186px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.text {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 20px;
}

.search {
  padding: 30px;
  border-radius: 16px;
  background: var(--clr-accent-semidark);

  --search-ph-color: var(--clr-n12);
  --search-bcolor: #2e3d7d;
  --search-bcolor-hover: #3a4988;

  @include maxWidth($bp-mobile) {
    width: 100%;
    padding: 24px;
  }
}
