@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.choice {
  display: block;
  cursor: pointer;
}

.radio {
  .title {
    &::before,
    &::after {
      border-radius: var(--choice-frame-radius, 50%);
    }

    &::after {
      top: var(--choice-circle-top, 16px);
      left: var(--choice-circle-left, 8px);
      background-image: none;
      background-color: #fff;
      width: 12px;
      height: 12px;
    }
  }
}

.radio_icon {
  position: relative;

  .title {
    padding-top: var(--choice-pt, 0);
    padding-left: var(--choice-pl, 0);
    padding-bottom: var(--choice-pb, 0);
    text-align: var(--choice-title-align, center);
    color: var(--choice-color, var(--clr-text-secondary));

    &::before,
    &::after {
      content: none;
    }
  }

  .input:not(:checked):not(:disabled) + .title {
    &:hover {
      color: var(--clr-text-secondary);
      --choice-icon-bcolor: var(--clr-n5);
    }
  }

  .input:focus:not(:checked) + .title {
    color: var(--clr-text-secondary);
    --choice-icon-bcolor: var(--clr-n5);
  }

  .input:checked + .title {
    color: var(--clr-n15);

    .icon {
      --choice-icon-bcolor: var(--clr-decor-main);

      &::before {
        opacity: 0;
      }

      svg {
        mix-blend-mode: normal;
        opacity: 1;
      }
    }
  }
}

.title {
  position: relative;
  display: block;
  padding-top: var(--choice-pt, 9px);
  padding-bottom: var(--choice-pb, 10px);
  padding-right: var(--choice-pr, 0);
  padding-left: var(--choice-pl, 38px);
  font-family: var(--choice-ff, inherit);
  font-weight: var(--choice-fw, inherit);
  font-size: var(--choice-fz, 20px);
  line-height: 1.3;
  color: var(--choice-color, var(--clr-n15));
  user-select: none;

  &::before,
  &::after {
    content: var(--choice-frame-content, '');
    position: absolute;
    top: var(--choice-frame-top, 8px);
    right: var(--choice-frame-right, auto);
    bottom: var(--choice-frame-bottom, auto);
    left: var(--choice-frame-left, 0);
    display: block;
    width: var(--choice-frame-size, 28px);
    height: var(--choice-frame-size, 28px);
  }

  &::before {
    background-color: var(--choice-frame-bg-color, var(--clr-n3));
    border: 1px solid var(--clr-n3);
    border-radius: 6px;
    transition: background-color 0.2s ease, border-color 0.2s ease;
  }

  &::after {
    opacity: 0;
    transform: scale(0.4);
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.33602 10.8482C2.9455 10.4577 2.9455 9.82453 3.33602 9.43401L11.6185 1.15157C12.009 0.761049 12.6421 0.76105 13.0327 1.15157C13.4232 1.5421 13.4232 2.17526 13.0327 2.56579L4.75023 10.8482C4.35971 11.2387 3.72655 11.2387 3.33602 10.8482Z' fill='%23ffffff'/%3E%3Cpath d='M4.74911 10.8486C4.35858 11.2391 3.72542 11.2391 3.33489 10.8486L0.966873 8.48054C0.576349 8.09001 0.57635 7.45685 0.966874 7.06633C1.3574 6.6758 1.99056 6.6758 2.38109 7.06633L4.74911 9.43435C5.13963 9.82487 5.13963 10.458 4.74911 10.8486Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 52% auto;
    transform-origin: center;
    transition: opacity 0s 0.2s, transform 0.2s ease-out;
  }
}

.icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--choice-icon-size, 100%);
  margin-bottom: var(--choice-icon-mb, 16px);
  border-radius: var(--choice-icon-radius, 50%);
  border: 2px solid var(--choice-icon-bcolor, var(--clr-n3));
  background: #fff;
  transition: border-color 0.2s ease, opacity 0.2s ease;

  svg {
    max-width: 49%;
    height: auto;
    mix-blend-mode: luminosity;
    opacity: 0.15;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: #4a4ce2;
    mix-blend-mode: color-burn;
    opacity: 0.2;
  }

  &::after {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  pointer-events: none;
  white-space: nowrap;

  &:checked + .title {
    &::before {
      background-color: var(--clr-decor-main);
      border-color: var(--clr-decor-main);
    }

    &::after {
      transform: scale(1);
      opacity: 1;
      transition: opacity 0s, transform 0.2s ease-out;
    }
  }

  &:focus + .title {
    color: var(--clr-text-accent);
  }

  &:focus:not(:checked) + .title {
    &::before {
      border-color: var(--clr-n7);
    }
  }

  &:disabled + .title {
    cursor: default;
    opacity: 0.5;
  }

  &:disabled:checked + .title {
    &::before {
      opacity: var(--choice-frame-disabled-opacity, 0.3);
    }
  }

  &:not(:checked):not(:disabled) + .title {
    &:hover {
      color: var(--clr-text-accent);

      &::before {
        border-color: var(--clr-n7);
      }
    }
  }
}
