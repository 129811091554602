@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.slider {
  padding-top: var(--full-width-slider-pt, 0);
  padding-bottom: var(--full-width-slider-pb, 0);
  overflow: hidden;
}

.swiper {
  --swiper-gap: 38px;

  :global(.swiper-container) {
    overflow: visible;
  }

  :global(.swiper-wrapper) {
    overflow: visible;
  }

  :global(.swiper-slide) {
    width: calc((100% - var(--swiper-gap) * 2) / 3);

    &:not(:last-child) {
      margin-right: var(--swiper-gap);
    }

    @include maxWidth($bp-tablet) {
      width: 361px;
    }

    @include maxWidth($bp-lMobile) {
      width: 246px;
    }
  }

  @include maxWidth($bp-lMobile) {
    --swiper-gap: 20px;
  }
}
