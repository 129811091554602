@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

$space-tablet: 48px;
$space-mobile: 20px;

.notifications {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
  pointer-events: none;
}

.list {
  position: absolute;
  left: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: minmax(0, 360px);
  grid-gap: 10px;
  width: 100%;
  padding: 32px;

  @include maxWidth($bp-tablet) {
    padding: 24px #{$space-tablet};
  }

  @include maxWidth($bp-lMobile) {
    padding: 24px #{$space-mobile};
  }
}
