@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.notification {
  pointer-events: all;

  &.error {
    --notification-bg-color: var(--clr-decor-main);
  }

  &.on-enter {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  &.on-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 0.2s ease-out, transform 0.2s ease-out;
  }

  &.on-exit {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  &.on-exit-active {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
    transition: opacity 0.2s ease-in, transform 0.2s ease-in;
  }
}

.body {
  display: flex;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background-color: var(--notification-bg-color, var(--clr-decor-main));
  border-radius: 14px;
}

.content {
  flex-grow: 1;
  padding: 20px 0px 24px 24px;
}

.close {
  flex-shrink: 0;
  display: block;
  width: 48px;
  height: 62px;
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='-.243' y='6.828' width='10' height='2' rx='1' transform='rotate(-45 -.243 6.828)' fill='%23ffffff'/%3E%3Crect x='1.172' y='-.243' width='10' height='2' rx='1' transform='rotate(45 1.172 -.243)' fill='%23ffffff'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  opacity: 0.7;
  transition: opacity 0.23s ease;

  @include noTouchHover() {
    opacity: 1;
  }
}
