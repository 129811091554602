@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.collapse {
  border: 1px solid var(--clr-n7);
  border-radius: 10px;
  overflow: hidden;
  transition: border-color 0.2s;

  &.disabled {
    opacity: 0.6;
    pointer-events: none;

    .toggle {
      cursor: default;
      &::after {
        content: none;
      }
    }
  }

  @include noTouchHover() {
    border-color: var(--clr-text-secondary);
  }
}

.inner {
  transition: height 300ms;
}

.content {
  padding: 0 16px 14px 16px;
}

.toggle {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 42px 11px 16px;
  font-size: 16px;
  font-weight: 500;
  color: var(--clr-text-accent);
  text-align: left;
  cursor: pointer;
  transition: color 0.2s ease-out;

  &::after {
    content: '';
    position: absolute;
    top: 11px;
    right: 9px;
    width: 24px;
    height: 24px;
    pointer-events: none;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.0323 1.08249C13.4228 1.47302 13.4228 2.10618 13.0323 2.49671L7.49677 8.03224C7.10624 8.42277 6.47308 8.42277 6.08255 8.03224C5.69203 7.64172 5.69203 7.00855 6.08255 6.61803L11.6181 1.08249C12.0086 0.691969 12.6418 0.691969 13.0323 1.08249Z' fill='%23162151'/%3E%3Cpath d='M0.503133 1.08244C0.893657 0.691919 1.52682 0.691919 1.91735 1.08244L7.45288 6.61798C7.8434 7.0085 7.8434 7.64167 7.45288 8.03219C7.06236 8.42272 6.42919 8.42272 6.03867 8.03219L0.503133 2.49666C0.112609 2.10613 0.112609 1.47297 0.503133 1.08244Z' fill='%23162151'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  }

  &.open {
    &::after {
      transform: rotate(180deg);
    }
  }
}
