@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.modal {
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: minmax(0, 1fr) max-content;
  grid-template-rows: max-content minmax(0, 1fr);
  grid-template-areas:
    'header close'
    'content content';
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: hidden;
  opacity: 0;
  background-color: rgba(6, 10, 30, 0.95);

  &.closable {
    cursor: pointer;
  }

  &.on-enter {
    opacity: 0;
  }

  &.on-enter-active {
    opacity: 1;
    transition: opacity 0.1s linear;
  }

  &.on-enter-done {
    opacity: 1;

    .container {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  &.on-exit {
    opacity: 1;
  }

  &.on-exit-active {
    opacity: 0;
    transition: opacity 0.1s linear;
  }
}

.header {
  grid-area: header;
}

.content {
  grid-area: content;
  width: 100%;
  height: 100%;
}

.close {
  grid-area: close;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 96px;
  color: var(--clr-n1);
  transition: opacity 0.2s;

  svg {
    width: 24px;
    height: auto;
  }

  &:hover {
    opacity: 0.8;
  }

  @include maxWidth($bp-lMobile) {
    width: 64px;
    height: 64px;
  }
}
