@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.html {
  color: var(--html-color, var(--clr-n15)) !important;
  font-family: var(--font-main);
  font-size: var(--html-fz, 20px);
  line-height: 1.6;
  text-align: left;

  & * {
    color: var(--html-color, var(--clr-n15)) !important;
    background-color: rgba(0, 0, 0, 0) !important;
  }

  strong,
  b {
    font-family: var(--font-secondary);
    font-weight: bold;
    color: var(--html-bold-color, var(--clr-text-accent)) !important;
  }

  a {
    display: inline;
    color: var(--html-link-color, var(--clr-text-accent)) !important;
    font-weight: 500;
    background-image: linear-gradient(180deg, var(--clr-n7), var(--clr-n7)) !important;
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    transition: color 0.25s linear;

    @include noTouchHover() {
      background-image: linear-gradient(
        180deg,
        var(--clr-text-secondary),
        var(--clr-text-secondary)
      ) !important;
    }
  }

  p {
    width: 100%;
    margin-bottom: 24px;
    white-space: pre-wrap;

    &:last-child {
      margin-bottom: 0;
    }

    &:first-child {
      & > img {
        margin-top: 0;
      }
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--font-secondary);
    font-weight: bold;
    color: var(--html-title-color, var(--clr-text-accent)) !important;
    line-height: 1.3;
  }

  h2 {
    font-size: var(--html-h2-fz, 32px);

    &:not(:first-child) {
      margin-top: 40px;
    }
  }

  h3,
  h4,
  h5,
  h6 {
    font-size: 24px;

    &:not(:first-child) {
      margin-top: 32px;
    }

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  ul,
  ol {
    padding-left: 38px;

    &:not(:first-child) {
      margin-top: 24px;
    }

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    li {
      position: relative;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  ul > li::before {
    content: '';
    position: absolute;
    top: 12px;
    right: calc(100% + 16px);
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: var(--clr-text-accent);
  }

  ol {
    counter-reset: ol-counter;
    li {
      counter-increment: ol-counter;

      &::before {
        content: counter(ol-counter) '.';
        position: absolute;
        top: 0;
        right: 100%;
        display: inline-block;
        padding-right: 10px;
        font-size: inherit;
        color: var(--clr-text-accent);
      }
    }
  }

  img {
    max-width: 100%;
    height: auto;
    margin-top: 32px;
    margin-bottom: 32px;
  }
}
