@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.button {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: var(--button-width, auto);
  padding-top: var(--button-pt, 8px);
  padding-bottom: var(--button-pb, 10px);
  padding-left: var(--button-pl, 24px);
  padding-right: var(--button-pr, 24px);
  font-size: var(--button-fz, 16px);
  line-height: var(--button-lh, 1.3);
  font-weight: var(--button-fw, bold);
  text-align: center;
  color: var(--button-color, var(--clr-accent-main));
  border: 1px solid var(--button-bcolor, var(--clr-accent-main));
  border-radius: var(--button-bradius, 10px);
  background: var(--button-background, rgba(255, 255, 255, 0));
  user-select: none;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s, filter 0.2s, color 0.1s;

  &:hover {
    --button-color: var(--clr-n1);
    --button-bcolor: var(--clr-accent-light);
    --button-background: var(--clr-accent-light);
  }

  &:active {
    --button-color: var(--clr-n1);
    --button-bcolor: var(--clr-accent-dark);
    --button-background: var(--clr-accent-dark);
  }
}

.decor {
  --button-color: var(--clr-decor-main);
  --button-bcolor: var(--clr-decor-main);

  &:hover {
    --button-color: var(--clr-n1);
    --button-bcolor: var(--clr-decor-semilight);
    --button-background: var(--clr-decor-semilight);
  }

  &:active {
    --button-color: var(--clr-n1);
    --button-bcolor: var(--clr-decor-dark);
    --button-background: var(--clr-decor-dark);
  }
}

.navigation {
  --button-color: var(--clr-text-main);
  --button-bcolor: var(--clr-n7);

  &:hover {
    --button-color: var(--clr-text-main);
    --button-bcolor: var(--clr-n4);
    --button-background: var(--clr-n4);
  }

  &:active {
    --button-color: var(--clr-text-main);
    --button-bcolor: var(--clr-n5);
    --button-background: var(--clr-n5);
  }
}

.primary {
  --button-color: var(--clr-n1);
  --button-background: var(--clr-accent-main);

  &:hover {
    --button-background: var(--clr-accent-light);
  }

  &:active {
    --button-background: var(--clr-accent-dark);
  }

  &.decor {
    --button-color: var(--clr-n1);
    --button-bcolor: var(--clr-decor-main);
    --button-background: var(--clr-decor-main);

    &:hover {
      --button-background: var(--clr-decor-semilight);
    }

    &:active {
      --button-background: var(--clr-decor-dark);
    }
  }

  &.navigation {
    --button-color: var(--clr-text-secondary);
    --button-bcolor: var(--clr-n3);
    --button-background: var(--clr-n3);

    &:hover {
      --button-color: var(--clr-text-main);
      --button-background: var(--clr-n4);
    }

    &:active {
      --button-color: var(--clr-text-main);
      --button-background: var(--clr-n5);
    }
  }
}

.large {
  padding-top: var(--button-pt, 17px);
  padding-bottom: var(--button-pb, 19px);
  padding-left: var(--button-pl, 48px);
  padding-right: var(--button-pr, 48px);
  font-size: var(--button-fz, 20px);
  border-radius: var(--button-bradius, 14px);
}

.disabled,
.disabled:hover {
  filter: grayscale(0.6);
  opacity: 0.5;
  pointer-events: none;
}
